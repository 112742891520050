import React from 'react';
import { intComma } from 'src/utils/utils';

const leftDivStyle = {
  flex: 1,
  textAlign: 'right',
};
const rightDivStyle = {
  flex: 1,
  textAlign: 'left',
  marginTop: '25px',
  marginLeft: '15px',
};

const Stat = ({ value, description, className, citySize, onClick }) => (
  <div className={`${className} pb2 w-100`} onClick={onClick} style={{ display: 'flex' }}>
    <div style={leftDivStyle}><span className={`f${citySize || 1} fw9 `} >{intComma(value)}</span></div>
    <div style={rightDivStyle}><span className="f4 fw5 i">{description}</span></div>
  </div >
);

export default Stat;
