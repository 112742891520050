// const
const MAPBOX_TOKEN =
  'pk.eyJ1IjoibGl1eGluZHRjIiwiYSI6ImNraHZxMTh6dzE4N2cydHBnN293OGVtajIifQ.L-zroe7VKLd6jhCTzbF65w';
//  'pk.eyJ1IjoieWlob25nMDYxOCIsImEiOiJja2J3M28xbG4wYzl0MzJxZm0ya2Fua2p2In0.PNKfkeQwYuyGOTT_x9BJ4Q';
const MUNICIPALITY_CITIES_ARR = [
  '上海市',
  '浙江省',
];
const MAP_LAYER_LIST = [
  'road-label',
  'waterway-label',
  'natural-line-label',
  'natural-point-label',
  'water-line-label',
  'water-point-label',
  'poi-label',
  'airport-label',
  'settlement-subdivision-label',
  'settlement-label',
  'state-label',
  'country-label',
];

// styling: set to `true` if you want dash-line route
const USE_DASH_LINE = true;
// styling: route line opacity: [0, 1]
const LINE_OPACITY = 0.5;
// styling: map height
const MAP_HEIGHT = 750;
//set to `false` if you want to hide the road label characters
const ROAD_LABEL_DISPLAY = true;

// IF you outside China please make sure IS_CHINESE = false
const IS_CHINESE = true;
const USE_ANIMATION_FOR_GRID = false;
// const CHINESE_INFO_MESSAGE = (yearLength, year) => {
// const yearStr = year === 'Total' ? '所有' : ` ${year} `;
//return `我用 App 记录自己跑步 ${yearLength} 年了，下面列表展示的是${yearStr}的数据`;
// return `最好的跑步状态：不是快，而是强大`;
// }
// const ENGLISH_INFO_MESSAGE = (yearLength, year) =>
//   `Running Journey with ${yearLength} Years, the table shows year ${year} data`;

// not support English for now
const CHINESE_LOCATION_INFO_MESSAGE_FIRST =
  '我跑过了一些地方，希望随着时间推移，地图点亮的地方越来越多';
const CHINESE_LOCATION_INFO_MESSAGE_SECOND = '不要停下来，不会停下奔跑的脚步';

// const INFO_MESSAGE = IS_CHINESE ? CHINESE_INFO_MESSAGE : ENGLISH_INFO_MESSAGE;
const INFO_MESSAGE = '最好的跑步状态：不是快，而是强大';
const INFO_MESSAGE_2 = '对一个跑者最好的称赞：不是快，而是坚强';
const FULL_MARATHON_RUN_TITLE = IS_CHINESE ? '【全马】' : 'Full Marathon';
const HALF_MARATHON_RUN_TITLE = IS_CHINESE ? '【半马】' : 'Half Marathon';
const MORNING_RUN_TITLE = '';
const MIDDAY_RUN_TITLE = '';
const AFTERNOON_RUN_TITLE = '';
const EVENING_RUN_TITLE = '';
const NIGHT_RUN_TITLE = '';
// const MORNING_RUN_TITLE = IS_CHINESE ? '晨跑' : 'Morning Run';
// const MIDDAY_RUN_TITLE = IS_CHINESE ? '午间跑' : 'Midday Run';
// const AFTERNOON_RUN_TITLE = IS_CHINESE ? '午后跑' : 'Afternoon Run';
// const EVENING_RUN_TITLE = IS_CHINESE ? '傍晚跑' : 'Evening Run';
// const NIGHT_RUN_TITLE = IS_CHINESE ? '夜跑' : 'Night Run';

const RUN_TITLES = {
  FULL_MARATHON_RUN_TITLE,
  HALF_MARATHON_RUN_TITLE,
  MORNING_RUN_TITLE,
  MIDDAY_RUN_TITLE,
  AFTERNOON_RUN_TITLE,
  EVENING_RUN_TITLE,
  NIGHT_RUN_TITLE,
};

export {
  CHINESE_LOCATION_INFO_MESSAGE_FIRST,
  CHINESE_LOCATION_INFO_MESSAGE_SECOND,
  MAPBOX_TOKEN,
  MUNICIPALITY_CITIES_ARR,
  MAP_LAYER_LIST,
  IS_CHINESE,
  ROAD_LABEL_DISPLAY,
  INFO_MESSAGE,
  INFO_MESSAGE_2,
  RUN_TITLES,
  USE_ANIMATION_FOR_GRID,
  USE_DASH_LINE,
  LINE_OPACITY,
  MAP_HEIGHT,
};

const nike = 'rgb(250, 64, 116)'; // if you want change the main color change here src/styles/variables.scss

// If your map has an offset please change this line
// issues #92 and #198
export const NEED_FIX_MAP = false;
export const MAIN_COLOR = nike;
export const TEXT_COLOR = 'rgb(52, 51, 50)';
// export const TEXT_COLOR = 'rgb(255, 255, 255)';
// export const PROVINCE_FILL_COLOR = '#47b8e0';
export const PROVINCE_FILL_COLOR = nike;
export const HR_COLOR = '#d9e5d6';
