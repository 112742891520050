import React from 'react';
import YearStat from 'src/components/YearStat';
import useActivities from 'src/hooks/useActivities';
import { INFO_MESSAGE, INFO_MESSAGE_2 } from 'src/utils/const';

const YearsStat = ({ year, onClick }) => {
  const { years } = useActivities();
  // make sure the year click on front
  let yearsArrayUpdate = years.slice();
  yearsArrayUpdate.push('Total');
  yearsArrayUpdate = yearsArrayUpdate.filter((x) => x !== year);
  yearsArrayUpdate.unshift(year);

  // for short solution need to refactor
  return (
    <div className="fl w-100-l pb5 pr5-l">
      <section className="pb4" style={{ paddingBottom: '0.1rem', paddingRight: '1rem' }}>
        <p style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'right', lineHeight: 1.8 }}>
          {INFO_MESSAGE}
          <br />
          {INFO_MESSAGE_2}
        </p>
      </section>
      {
        yearsArrayUpdate.map((year) => (
          <YearStat key={year} year={year} onClick={onClick} />
        ))
      }
      {
        yearsArrayUpdate.hasOwnProperty('Total') ? (
          <YearStat key="Total" year="Total" onClick={onClick} />
        ) : (
          <div />
        )
      }
    </div >
  );
};

export default YearsStat;
