import React from 'react';
import { MAIN_COLOR, TEXT_COLOR } from 'src/utils/const';
import { formatPace, titleForRun, formatRunTime, formatAvgPace, formatShowTime, formatSecondTime, convertToSecond } from 'src/utils/utils';
import styles from './style.module.scss';

const RunRow = ({ runs, run, locateActivity, runIndex, setRunIndex }) => {
  const ShoeName = (a) => {
    const name = new Map([
      ['1bba10ea-b686-467a-8ace-9234a90a05e0', 'Pegasus 37'],
      ['a9899fd2-0498-4f0c-be7b-f99ceead6920', 'Tempo Next%'],
      ['4a3d7386-2e11-4f47-bbad-2fcd0c61bbc5', 'Vaporfly NEXT%'],
      ['4ba3e24d-c089-4394-a3c4-5a49ff2c0b7e', 'Alphafly Next%'],
      ['4b6cfcf5-314e-4d40-8427-7bc7807a21b9', 'Epic React'],
      ['4157d05c-32d1-48da-a10f-db5a762543e9', 'kayano 21'],
      ['6f730d4f-2cb9-4788-8f7b-40deda3d192e', 'Nike Dualtone Racer iD'],
      ['236c6f0c-5011-4b8c-b21e-78e70c9783ad', 'Pegasus Turbo 2'],
      ['0a37b661-8cf6-4b38-9435-f809ae35b0dd', 'Fly 3'],
      ['622d536b-5bcc-45f2-8139-820ed9c5e4ab', 'lining 14'],
      ['d2c4916b-9663-4e8f-bf74-d7daa2b80394', 'air max 2011'],
      ['c51c347e-943a-4c22-a5e5-32002c0c1483', 'adidas sonic boost'],
      ['04d8f76b-1ab1-4cba-916a-380efb8e48dc', 'Epic'],
      ['3419958336', 'no shoe'],
      ['noShoeItemID', 'no shoe']
    ]).get(a);
    return name ? name : (a ? a : 'freestyle');
  }


  const DayString = (day) => {
    if (day < 0 || day > 6) {
      return "未知";
    }
    return "周" + "日一二三四五六".charAt(day);
  }

  const distance = (run.distance / 1000.0).toFixed(1);
  // const pace = run.average_speed;
  // const paceParts = pace ? formatPace(pace) : null;
  // const runTime = formatRunTime(distance, pace);

  const date = new Date(run.start_date_local);
  const day = DayString(date.getDay());
  const pace = run.average_pace ? formatAvgPace(run.average_pace) : 'None';
  const heartRate = run.average_heartrate;
  const step = run.total_step ? Math.floor(run.total_step / (convertToSecond(run.moving_time) / 60)) : 'None';
  const runTime = formatRunTime(distance, run.average_pace);
  console.log(run);
  const totalTime = formatShowTime(run.elapsed_time);
  const restTime = formatSecondTime(Math.abs(convertToSecond(run.elapsed_time) - convertToSecond(runTime)));
  const temperature = run.temperature ? Math.floor(run.temperature) + '°' : 'None';
  const shoe = ShoeName(run.shoe_id);
  const weather_temperature = run.weather_temperature != null ? Math.floor(run.weather_temperature) + '°' : 'None';
  const title = `${titleForRun(run)}${run.name}`;

  // change click color
  const handleClick = (e, runs, run) => {
    const elementIndex = runs.indexOf(run);
    e.target.parentElement.style.color = MAIN_COLOR;

    const elements = document.getElementsByClassName(styles.runRow);
    if (runIndex !== -1 && elementIndex !== runIndex) {
      elements[runIndex].style.color = TEXT_COLOR;
    }
    setRunIndex(elementIndex);
  };

  return (
    <tr
      className={styles.runRow}
      key={run.start_date_local}
      onClick={(e) => {
        handleClick(e, runs, run);
        locateActivity(run);
      }}
    >
      <td>{title}</td>
      <td>{distance}</td>
      <td>{shoe}</td>
      <td>{pace}</td>
      <td>{heartRate && heartRate.toFixed(0)}</td>
      <td>{step}</td>
      <td>{weather_temperature}</td>
      <td>{temperature}</td>
      <td>{runTime}</td>
      <td>{restTime}</td>
      {/* <td>{totalTime}</td> */}
      <td>{day}</td>
      {/* <td className={styles.runDate}>{run.start_date_local}</td> */}
      <td>{run.start_date_local}</td>
    </tr>
  );
};

export default RunRow;
